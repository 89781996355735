import React from "react";
import { Link } from "react-router-dom";
import "./menubar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// const Menubar = (props) => {
//   return (
//     <div class="navbar">
//       <Link to="/">
//         <img
//           src="/garden-go-logo-ipad-resized-64x-64x.png"
//           width="16"
//           height="16"
//         />
//       </Link>
//       <Link to="/dashboard">Dashboard</Link>
//       <Link to="/status">Status</Link>
//       <Link to="/about">About</Link>
//     </div>
//   );
// };

const Menubar = (props) => {
  return (
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div class="container-fluid">
        <Link to="/">
          <img
            src="/garden-go-logo-ipad-resized-64x-64x.png"
            width="24"
            height="24"
          />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li class="nav-item">
              <Link to="/plants">Plants</Link>
            </li>
            <li class="nav-item">
              <Link to="/insights">Insights</Link>
            </li>
            <li class="nav-item">
              <Link to="/status">Status</Link>
            </li>
            <li class="nav-item">
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const img = {
  // marginRight: "200%",
  verticalAlign: "-15%",
  // padding: "10px",
};

const MenubarTwo = (props) => {
  return (
    // <Navbar bg="light" expand="lg">
    <>
      <Navbar
        expand="lg"
        bg="dark"
        variant="dark"
        // sticky="top"
        className="fixed-top"
      >
        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
        {/* <img
          src="/garden-go-logo-ipad-resized-64x-64x.png"
          width="24"
          height="24"
          className="d-inline-block align-middle align-right"
          // style={img}
        /> */}
        <Container>
          <Navbar.Brand href="/">
            <img
              src="/garden-go-logo-ipad-resized-64x-64x.png"
              width="24"
              height="24"
              className="d-inline-block"
              style={img}
            />{" "}
            GardenGo
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/dashboard">Dashboard</Nav.Link>
              {/* <Nav.Link href="/plants">Plants</Nav.Link>
              <Nav.Link href="/status">Status</Nav.Link> */}
              <Nav.Link href="/insights">Insights</Nav.Link>
              {/* <Nav.Link href="/subscribe">Subscribe</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

// export default Menubar;
export default MenubarTwo;
