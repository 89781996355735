// import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

const footerContent = {
  maxWidth: "980px",
};

const websiteUrls = [
  {
    text: "Website base - HTML & CSS",
    url: "https://www.tinkercad.com/",
  },
  {
    text: "Website base - React",
    url: "https://www.w3schools.com/howto/howto_make_a_website.asp",
  },
];

const sensorUrls = [
  {
    text: "Adafruit",
    url: "https://www.adafruit.com/",
  },
  {
    text: "Stemma QT",
    url: "https://learn.adafruit.com/introducing-adafruit-stemma-qt",
  },
  {
    text: "Pi Weather Station<",
    url: "https://projects.raspberrypi.org/en/projects/build-your-own-weather-station",
  },
];

const stencilUrls = [
  {
    text: "3D printing resources",
    url: "https://all3dp.com/",
  },
  {
    text: "SVD sesigner",
    url: "https://inkscape.org/",
  },
  {
    text: "SVD to STL converter",
    url: "https://www.tinkercad.com/",
  },
  {
    text: "Slicer software",
    url: "",
  },
];

const footerStyle = {
  background: "rgba(247, 209, 160, 0.25)",
};

const codebaseComingSoonMsg =
  "Codebases are currently under maintenance, please check back later.";

const links = {
  codebase: [
    {
      desc: "GardenGo (Group)",
      message: codebaseComingSoonMsg,
    },
    {
      desc: "Backend & API (AWS)",
      message: codebaseComingSoonMsg,
    },
    {
      desc: "Sensors (RPi)",
      message: codebaseComingSoonMsg,
    },
    {
      desc: "Web",
      message: codebaseComingSoonMsg,
    },
  ],
  keyResources: [
    {
      desc: "Adafruit",
      url: "https://www.adafruit.com",
    },
    {
      desc: "RPi Weather Station",
      url: "https://projects.raspberrypi.org/en/projects/build-your-own-weather-station",
    },
    {
      desc: "Bootstrap React",
      url: "https://react-bootstrap.github.io",
    },
    {
      desc: "Tinkercad",
      url: "https://www.tinkercad.com",
    },
  ],
};

class FooterLink extends React.Component {
  constructor(props) {
    super();
    this.state = {
      linkInfo: props.linkInfo,
      show: false,
      setShow: false,
    };
  }

  buildModalBodyText() {
    if (!!this.state.linkInfo.message) {
      return this.state.linkInfo.message;
    } else {
      return `${this.state.linkInfo.desc} link has not been added yet, please check back later.`;
    }
  }

  render() {
    const handleClose = () => {
      this.setState({ show: false });
    };
    const handleShow = () => {
      this.setState({ show: true });
    };

    return (
      <>
        <a onClick={handleShow} class="nav-link p-0 text-muted">
          {this.state.linkInfo.desc}
        </a>
        <Modal show={this.state.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.linkInfo.desc}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.buildModalBodyText()}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class FooterLinks extends React.Component {
  buildFooterLink(linkInfo) {
    return (
      <li class="nav-item mb-2">
        {linkInfo.url ? (
          <a href={linkInfo.url} class="nav-link p-0 text-muted">
            {linkInfo.desc}
          </a>
        ) : (
          <FooterLink linkInfo={linkInfo} />
        )}
      </li>
    );
  }

  render() {
    return (
      <>
        <div class="col mb-3">
          <h5>Codebase</h5>
          <ul class="nav flex-column">
            {links.codebase.map((link) => this.buildFooterLink(link))}
          </ul>
        </div>
        <div class="col mb-3">
          <h5>Key Resources</h5>
          <ul class="nav flex-column">
            {links.keyResources.map((link) => this.buildFooterLink(link))}
          </ul>
        </div>
      </>
    );
  }
}

const Footer = (props) => {
  return (
    <div style={footerStyle}>
      <div class="container">
        <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 border-top">
          <div class="col mb-3">
            <a
              href="/"
              class="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img
                src="/garden-go-logo-ipad-resized-64x-64x.png"
                width="40"
                height="40"
              />
            </a>
            <p class="text-muted">&copy; 2022</p>
          </div>

          <div class="col mb-3"></div>

          <FooterLinks />
        </footer>
      </div>
      <div class="container" style={subFooterStyle}>
        <footer class="row row-cols-1 py-5 border-top text-center">
          <p class="nav-link p-0 text-muted">
            GardenGo is licensed under MIT.
            {/* {" "}
            <a
              href="https://github.com/twbs/bootstrap/blob/main/LICENSE"
              class="p-0 text-muted"
              // target="_blank"
              // rel="license noopener"
            >
              MIT
            </a>
            . */}
          </p>
        </footer>
      </div>
    </div>
  );
};

const subFooterStyle = {
  display: "flex !important;",
  justifyContent: "space-between",
};

export default Footer;

{
  /* <h2>Resources</h2>
            <h3>Sensors</h3>
            <h4 href="https://www.adafruit.com/">Adafruit</h4>
            <h4 href="https://learn.adafruit.com/introducing-adafruit-stemma-qt">Stemma QT</h4>
            <h4 href="https://projects.raspberrypi.org/en/projects/build-your-own-weather-station">Pi Weather Station</h4>

            <h3>3D Printing</h3>
            <h4 href="https://all3dp.com/">3D printing resources</h4>
            

            <h3>Stencils</h3>
            <h4 href="https://inkscape.org/">SVD Designer </h4>
            <h4 href="https://www.tinkercad.com/">SVD -> STL</h4>

            <h3>AWS</h3>
            <h4>Route53</h4>

            <h3>Website</h3>
            <h4 href="https://www.tinkercad.com/">Website base - HTML & CSS</h4>
            <h4 href="https://www.w3schools.com/howto/howto_make_a_website.asp">Website base - React</h4> */
}
