import { ConsoleLogger } from "@aws-amplify/core";

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import axios from "axios";
import { Grid, ThreeDots } from "react-loader-spinner";

import Menubar from "../components/menubar";
import Footer from "../components/footer/footer";
import MainLoadingGrid from "../components/main-loading-grid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from "faker";
// import { faker } from "@faker-js/faker";

import { FiDroplet, FiThermometer } from "react-icons/fi";
import { RiCompass2Line } from "react-icons/ri";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// import Chart from "chart.js/auto";
const feather = require("feather-icons");
const dataCategory = "hourly";

const Canvas = (props) => {
  const canvasRef = useRef(null);

  return <canvas ref={canvasRef} {...props} />;
};

async function getSensors() {
  const url = "https://api.gardengo.xyz/sensors";

  const response = await axios.get(url, {
    headers: {
      Authorization: localStorage.getItem("jwtEncoded"),
      "Content-Type": "application/json",
    },
  });

  return response.data.sensors;
}

async function getSensor(sensorId) {
  // console.log(`calling ${sensorId}`);
  const url = `https://api.gardengo.xyz/sensors/${sensorId}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: localStorage.getItem("jwtEncoded"),
      "Content-Type": "application/json",
    },
  });

  return response.data;
}

// const [checked, setChecked] = false;
// const [radioValue, setRadioValue] = '1';

const radios = [
  { name: "Active", value: "1" },
  { name: "Radio", value: "2" },
  { name: "Radio", value: "3" },
];

const accordionStyle = {
  backgroundColor: "#40e0d040",
};

function mainLoadingSpinner() {
  return (
    <Container>
      <br />
      <br />
      <br />
      <br />
      <br />

      <MainLoadingGrid size="325" />
    </Container>
  );
}

function sortSensors(sensors) {
  return sensors.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
}

function buildAccordionCategory(category) {
  return (
    <>
      <h2>{category.name}</h2>
      <Accordion style={accordionStyle}>
        {sortSensors(category.sensors).map((sensor) =>
          buildAccordionItem(sensor)
        )}
      </Accordion>
      <br />
    </>
  );
}

const fontWeightStyle = (weight, size) => {
  return {
    fontWeight: weight,
    fontSize: size,
  };
};

function buildDatasets(sensor) {
  // console.log(sensor);
  if (sensor[dataCategory].data) {
    return [
      {
        label: sensor.units,
        data: sensor[dataCategory].data,
        borderColor: "rgb(62, 224, 207)",
        backgroundColor: "rgba(62, 224, 207, 0.25)",
      },
    ];
  } else if (sensor[dataCategory].datasets) {
    const arrayLength = sensor[dataCategory].datasets.length;
    const increment = 0.5 / arrayLength;

    const datasets = [];
    for (let i = 0; i < arrayLength; i++) {
      const dataset = sensor[dataCategory].datasets[i];

      datasets.push({
        label: dataset.units,
        data: dataset.data,
        borderColor:
          i % 2 == 0
            ? `rgba(62, 224, 207, ${increment * i + 0.5})`
            : `rgba(247, 209, 160, ${increment * i + 0.5})`,
        backgroundColor:
          i % 2 == 0
            ? `rgba(62, 224, 207,${increment * i})`
            : `rgba(247, 209, 160,${increment * i})`,
      });
    }

    return datasets;
  } else {
    throw new Error("Sensor data type not recognized!");
  }
}

function buildChart(sensor) {
  // console.log("buildChart");
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const labels = sensor[dataCategory].labels;

  const data = {
    labels,
    datasets: buildDatasets(sensor),
    // datasets: [
    //   {
    //     label: sensor.units,
    //     data: sensor[dataCategory].data,
    //     borderColor: "rgb(255, 99, 132)",
    //     backgroundColor: "rgba(255, 99, 132, 0.5)",
    //   },
    // ],
  };

  return <Line options={options} data={data} />;
}

const colStyle = {
  backgroundColor: "rgba(247, 209, 160, 0.25)",
};

function areSensorReadingsAvailible(sensor, dataCategory) {
  return (
    getSensorReading(sensor, dataCategory, "current") !== "N/A " ||
    getSensorReading(sensor, dataCategory, "min") !== "N/A " ||
    getSensorReading(sensor, dataCategory, "max") !== "N/A "
  );
}

function getSensorReading(sensor, dataCategory, readingCategory) {
  if (
    sensor &&
    sensor[dataCategory] &&
    sensor[dataCategory][readingCategory] &&
    typeof sensor[dataCategory][readingCategory].Reading !== "undefined"
  ) {
    return sensor[dataCategory][readingCategory].Reading;
  } else {
    return "N/A ";
  }
}

function getReadingDate(sensor, dataCategory, readingCategory) {
  if (
    sensor &&
    sensor[dataCategory] &&
    sensor[dataCategory][readingCategory] &&
    sensor[dataCategory][readingCategory].date
  ) {
    return sensor[dataCategory][readingCategory].date.toLocaleString();
  } else {
    return "N/A";
  }
}

function buildAccordionItem(sensor) {
  // console.log("buildAccordionItem");
  return (
    <Accordion.Item eventKey={sensor.name}>
      <Accordion.Header>{sensor.fullName}</Accordion.Header>
      <Accordion.Body>
        {sensor.isDetailed ? (
          <Container>
            {areSensorReadingsAvailible(sensor, dataCategory) ? (
              <Row>
                <Col style={colStyle}>
                  <h2 style={fontWeightStyle("300")}>Current: </h2>{" "}
                  <h1 style={fontWeightStyle("300")}>
                    {getSensorReading(sensor, dataCategory, "current")}
                    {sensor.units}
                  </h1>
                  <h4 style={fontWeightStyle("300")}>
                    {getReadingDate(sensor, dataCategory, "current")}
                  </h4>
                </Col>
                <Col>
                  <h2 style={fontWeightStyle("300")}>Min: </h2>{" "}
                  <h1 style={fontWeightStyle("300")}>
                    {getSensorReading(sensor, dataCategory, "min")}
                    {sensor.units}
                  </h1>
                  <h4 style={fontWeightStyle("300")}>
                    {getReadingDate(sensor, dataCategory, "min")}
                  </h4>
                </Col>
                <Col>
                  <h2 style={fontWeightStyle("300")}>Max: </h2>{" "}
                  <h1 style={fontWeightStyle("300")}>
                    {getSensorReading(sensor, dataCategory, "max")}
                    {sensor.units}
                    <h4 style={fontWeightStyle("300")}>
                      {getReadingDate(sensor, dataCategory, "max")}
                    </h4>
                  </h1>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row>{buildChart(sensor)}</Row>
          </Container>
        ) : (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#40e0d040"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}

function buildSensorCategories(sensors) {
  // Filter out sensors that should not be displayed
  // console.log(sensors);
  const filteredSensors = sensors.filter((sensor) => sensor.display);
  // console.log(filteredSensors);

  // Get categories
  const categories = [];
  for (let sensor of filteredSensors) {
    let categoryFound = false;
    for (let category of categories) {
      if (category.name == sensor.category) {
        categoryFound = true;
      }
    }

    if (!categoryFound) {
      categories.push({
        name: sensor.category,
        sensors: [],
      });
    }
  }

  // Sort categories
  categories.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));

  // Add sensors to categories
  for (let category of categories) {
    for (let sensor of filteredSensors) {
      if (category.name == sensor.category) {
        category.sensors.push(sensor);
      }
    }
  }

  return categories;
}

function processRecord(record) {
  // console.log(record);
  if (!record || !record["UnixTs"]) {
    // console.log("returning record");
    // return {};
    return record;
  }

  record["date"] = new Date(record["UnixTs"] * 1000);
  return record;
}

function processRecordCategory(recordCategory) {
  // const processedRecords = [];
  // for (let record of recordCategory.records) {
  //   const processedRecord = processRecord(record);
  //   processedRecords.push(processedRecord);
  // }

  // console.log(recordCategory);

  const processedRecordCategory = recordCategory;

  processedRecordCategory.max = processRecord(recordCategory.max);
  processedRecordCategory.min = processRecord(recordCategory.min);
  processedRecordCategory.latest = processRecord(recordCategory.latest);
  processedRecordCategory.current = processRecord(recordCategory.current);

  return processedRecordCategory;

  // return {
  //   max: processRecord(recordCategory.max),
  //   min: processRecord(recordCategory.min),
  //   latest: processRecord(recordCategory.latest),
  //   current: processRecord(recordCategory.current),
  //   labels: recordCategory.labels,
  //   data: recordCategory.data,
  //   // records: processedRecords,
  // };
}

function processSensorData(sensorDetailed) {
  const processedSensorData = sensorDetailed;

  processedSensorData.hourly = processRecordCategory(sensorDetailed.hourly);
  processedSensorData.daily = processRecordCategory(sensorDetailed.daily);
  processedSensorData.isDetailed = true;

  return processedSensorData;
}

function timeSelector() {
  return (
    <Container>
      <Row className="float-end">
        <Col>
          <Nav variant="pills" defaultActiveKey="link-0">
            <Nav.Item>
              <Nav.Link eventKey="link-0">Hourly</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1">Daily</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}

class Overview extends React.Component {
  buildSensorOverview(sensors) {
    const sensorOverviewMapping = {
      ws0mt: {
        metric: "Temperature",
        icon: {
          component: FiThermometer,
          color: "#41414180",
        },
        order: 0,
      },
      ws0sh: {
        metric: "Humidity",
        icon: {
          component: FiDroplet,
          color: "#41414180",
        },
        order: 1,
      },
      // ws1bp: {
      //   metric: "Pressure",
      //   icon: {
      //     component: RiCompass2Line,
      //     color: "#41414180",
      //   },
      //   order: 2,
      // },
    };

    const sensorOverview = [];
    for (let sensor of sensors) {
      if (sensor.name in sensorOverviewMapping) {
        let sensorOverviewItem = {
          metric: sensorOverviewMapping[sensor.name].metric,
          order: sensorOverviewMapping[sensor.name].order,
          icon: sensorOverviewMapping[sensor.name].icon,
          sensor: sensor,
        };

        sensorOverview.push(sensorOverviewItem);
      }
    }

    sensorOverview.sort((a, b) => a.order - b.order);

    return sensorOverview;
  }

  buildIcon(icon) {
    const OverviewIcon = icon.component;

    return <OverviewIcon color={icon.color} size={48} />;
  }

  buildGradientStyle() {
    return {
      backgroundImage:
        // "linear-gradient(to bottom right, white, white, blue, red, red)",
        "linear-gradient(to bottom right, white,  white, #ffbbbb, #ff5757)",
      boxShadow: "0 0 8px 8px white inset",
    };
  }

  // buildMeasurementStyle() {
  //   return {
  //     fontSize: "50px",
  //   };
  // }

  buildHighlight(keySensor, colStyle) {
    return (
      <Col style={colStyle}>
        {/* <Container> */}
        <Row>
          <Col xs="auto">
            {!!keySensor.icon ? this.buildIcon(keySensor.icon) : null}
          </Col>
          <Col>
            <h1 style={fontWeightStyle("300", 45)}>{keySensor.metric}</h1>
          </Col>
          <Col>
            <h1 class="text-right" style={fontWeightStyle("300", 85)}>
              {getSensorReading(keySensor.sensor, dataCategory, "current")}
              {keySensor.sensor.units}
            </h1>
          </Col>
        </Row>
        {/* <Row style={this.buildGradientStyle()}> */}
        {/* <Col xs="auto">
            {!!keySensor.icon ? this.buildIcon(keySensor.icon) : null}
          </Col>
          <Col>
            <h1 style={fontWeightStyle("300")}>{keySensor.metric}</h1>
          </Col>
          <Col>
            <h1 class="text-right" style={fontWeightStyle("300")}>
              {getSensorReading(keySensor.sensor, dataCategory, "current")}
              {keySensor.sensor.units}
            </h1>
          </Col> */}
        {/* <Col>
            <h2 style={fontWeightStyle("300")}>Min: </h2>{" "}
            <h1 style={fontWeightStyle("300")}>
              {getSensorReading(sensor, dataCategory, "min")}
              {sensor.units}
            </h1>
            <h4 style={fontWeightStyle("300")}>
              {getReadingDate(sensor, dataCategory, "min")}
            </h4>
          </Col> */}
        {/* <Row>Temperature</Row>
         <Row>-1</Row> */}
        {/* </Row> */}
        {/* </Container> */}
      </Col>
    );
  }

  render() {
    const styleAquaGreen = {
      backgroundImage:
        // "linear-gradient(to bottom right, white, white, blue, red, red)",
        "linear-gradient(to bottom right, white, #40e0d040)",
      boxShadow: "0 0 8px 8px white inset",
    };

    const styleLightTan = {
      backgroundImage:
        // "linear-gradient(to bottom right, white, white, blue, red, red)",
        "linear-gradient(to bottom right, white, #f7d11040)",
      boxShadow: "0 0 8px 8px white inset",
    };

    const keySensors = this.buildSensorOverview(this.props.sensors);
    // const highlights = keySensors.map((keySensor) =>
    //   this.buildHighlight(keySensor)
    // );

    const highlights = [];
    if (keySensors.length > 0) {
      highlights[0] = this.buildHighlight(keySensors[0], styleAquaGreen);
      highlights[1] = this.buildHighlight(keySensors[1], styleLightTan);
    }

    return (
      <>
        <Container>
          <h1>Overview</h1>
          <h2>North Phoenix, AZ</h2>
          {/* {highlights} */}
          <Row>{highlights}</Row>
        </Container>
      </>
    );
  }
}

class Dashboard extends React.Component {
  constructor() {
    super();

    const sensors = [];
    this.state = {
      sensors,
      // sensorsDetailed: [],
      sensorCategories: buildSensorCategories(sensors),
    };
  }

  async componentDidMount() {
    const sensors = await getSensors();
    const displaySensors = sensors.filter(
      (sensor) => sensor.display // && sensor.name != "ws1p"
    );
    // console.log(displaySensors);

    const sensorDetailsPromises = displaySensors.map(async (sensor) => {
      const sensorsDetailed = await getSensor(sensor.name);

      return processSensorData(sensorsDetailed);
    });

    this.setState({
      sensors: displaySensors,
      sensorCategories: buildSensorCategories(sensors),
    });

    const sensorDetails = await Promise.all(sensorDetailsPromises);
    this.setState({
      sensors: sensorDetails,
      sensorCategories: buildSensorCategories(sensorDetails),
    });

    // TODO: Implement https://stackoverflow.com/questions/48601813/how-to-call-an-api-every-minute-for-a-dashboard-in-react
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <br />
        <Menubar />
        {/* {timeSelector()} */}
        {/* {mainLoadingSpinner()} */}
        <Overview sensors={this.state.sensors} />
        <br />
        <br />

        <Container>
          <h1>All Sensors</h1>

          {/* {console.log(this.state.sensorsByCategory)} */}
          {/* {this.state.sensors.map((sensor) => buildAccordionItem(sensor))} */}
          {this.state.sensorCategories.map((category) =>
            buildAccordionCategory(category)
          )}
        </Container>
        <br />
        <br />
        <br />
        <br />

        <Footer />
      </div>
    );
  }
}

export default Dashboard;
