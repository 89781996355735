import React from "react";
import axios from "axios";
import MainLoadingGrid from "../components/main-loading-grid";
import Container from "react-bootstrap/esm/Container";

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

var dashboard;
function onDashboardLoad(payload) {
  console.log("Do something when the dashboard is fully loaded.");
}

function onError(payload) {
  console.log("Do something when the dashboard fails loading");
}

// const url =
//   "https://us-east-1.quicksight.aws.amazon.com/embed/af8069d0e89e4f9f9dbd007d7b69e902/dashboards/e09f3be7-fedc-42c6-a1a6-7f45b2e725bb?code=AYABeO-j-X8smJ7AADVhOprwB6sAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4EeOLgrUr51nsHbjCawUUKjOqEm284CNxqOjvtm6TGiwBUJBVwaFppdo8V0d4SGfTLwAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDNh6PTjBHfFUtwAMRgIBEIA7lX_6WFsjmZgaA0KlLl4qBqeo0fLcnoZwDTKiPq62S_xoa8SvjAYiv-EdKOSUu1WJ-ZPzJ1JSRQNTTcYCAAAAAAwAABAAAAAAAAAAAAAAAAAAYB9GMQ5JUDX7NvlWcmAePv____8AAAABAAAAAAAAAAAAAAABAAAA5YsGnM8Lj3kr2Jt4T1OCEVue2RKDSyPNz6dfG3rcbDOGYvzQ38Ujk5iBuyH037XsdKL41WDiUTepxoBacotyAaykUPKmV504afXcX0xPsK2pdO8Ngpxd9xdD0pGgVwN7LcdNLIaNrHciZFuIBhtm7xSPuRdXShhFslTkuxWTmXhALxs9yU6P3mnilpnKl7Xx1zlL1xNyyki8ICRt26Cr8uQGjkc9Wqv_eNSLZNs9ERY2ZgK96ti211V3j6woyMCvzMhrkhQKMAxFrPwahDbYXTLn4k0ptnSKu73SxDJ5eybsju8k3qvmxUY4ZfdQKwdNTNmF8tsV&identityprovider=quicksight&isauthcode=true";
function embedDashboard(url) {
  if (url === "") return;

  var containerDiv = document.getElementById("embeddingContainer");
  var options = {
    // replace this dummy url with the one generated via embedding API
    url: url,
    container: containerDiv,
    parameters: {
      country: "United States",
    },
    scrolling: "no",
    height: "700px", // TODO: Scale with page using React state & page events
    // width: "1000px",
    width: parentWidth(document.getElementById("embeddingContainer")),
    locale: "en-US",
    footerPaddingEnabled: true,
  };
  dashboard = QuickSightEmbedding.embedDashboard(options);
  dashboard.on("error", onError);
  dashboard.on("load", onDashboardLoad);
}

function parentWidth(elem) {
  return elem.parentElement.clientWidth;
}

// function onCountryChange(obj) {
//   dashboard.setParameters({ country: obj.value });
// }

class QuickSightDashboard extends React.Component {
  constructor() {
    super();

    const username = JSON.parse(localStorage.getItem("jwtDecoded")).username;

    this.state = {
      quicksightUrl: "",
      quicksightIsLoaded: false,
      username: username,
    };
  }

  async componentDidMount() {
    const url = `https://api.gardengo.xyz/quicksight/users/${this.state.username}`;

    // TODO: Check if url is expired
    let quicksightUrl = this.state.quicksightUrl;
    if (quicksightUrl === "") {
      const response = await axios.post(url, {
        headers: {
          Authorization: localStorage.getItem("jwtEncoded"),
          "Content-Type": "application/json",
        },
      });

      console.log(response);
      console.log("got response");
      console.log(response.data.url);

      quicksightUrl = response.data.url;

      this.setState({
        quicksightUrl,
        quicksightIsLoaded: true,
      });
    }

    embedDashboard(quicksightUrl);
  }

  render() {
    return (
      //       {/* {dashboard} */}
      //       {/* <body onload="embedDashboard()">
      //         <span>
      //           <label for="country">Country</label>
      //           <select id="country" name="country" onchange="onCountryChange(this)">
      //             <option value="United States">United States</option>
      //             <option value="Mexico">Mexico</option>
      //             <option value="Canada">Canada</option>
      //           </select>
      //         </span>
      //         <div id="embeddingContainer"></div>
      //       </body> */}
      // <h1>Hello world</h1>
      <div>
        <br />
        <br />

        {!this.state.quicksightIsLoaded ? (
          <MainLoadingGrid size="400" />
        ) : (
          <Container>
            <h1>GardenGo Sensor Insights</h1>
            <p>Note: Sensor readings are updated weekly.</p>
          </Container>
        )}
        <Container id="quicksight-container">
          <div id="embeddingContainer"></div>
        </Container>
      </div>
    );
  }
}

// const QuickSightDashboard = (props) => {
//   const srcUrl =
//     "https://us-east-1.quicksight.aws.amazon.com/embed/2480811293114dc289499398109738fc/dashboards/e09f3be7-fedc-42c6-a1a6-7f45b2e725bb?code=AYABeNNXaApOKWX41Qx0E7hHBbgAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4EeOLgrUr51nsHbjCawUUKjOqEm284CNxqOjvtm6TGiwBFw_FYCqBamsAkFBvYh_d2gAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDM4O7PVCJVOg0dgiNgIBEIA73B7b4a2zmnts8qZITPw3hv_J9TGr_6kTkGxk4EGFJvwRVQ6q7-i3oCEOpd9AEguWTJj9nVJxp1yQ-YICAAAAAAwAABAAAAAAAAAAAAAAAAAAmbslurkgxJHzod--QKM_d_____8AAAABAAAAAAAAAAAAAAABAAAA5cM6CXmo1qh0KQAJEIMDI6DZA_ojydxJ10Yn3-kS1gp3uK0sjFh7J-4KP1BLkvzdYRVrqMNcbLu_7b_8Qrloi5j2rFFAJLptZmbCUXsuKig01onit2eqwUuFOfvkyT0V4mO0-AVKsj4qMFab6ovRnIZGTShtfSiDtx77ymthwIr4eSVZY7dTOgcrUfhN8Vbt2aGzPyG8Kxz51fGEyI70O7ue96-VRoTFoBOpPmAt92EvVd8uvJla0ORWQCVe--HhSWshgx7tmuUocpBRedA2VkfXXyBQmOcPGk_4q3XGWNGP98elvfk7YLVhX_0Un5d0DK8k9qtb&identityprovider=quicksight&isauthcode=true";
//   embedDashboard();

//   return (
//     <div>
//       {/* {dashboard} */}
//       {/* <body onload="embedDashboard()">
//         <span>
//           <label for="country">Country</label>
//           <select id="country" name="country" onchange="onCountryChange(this)">
//             <option value="United States">United States</option>
//             <option value="Mexico">Mexico</option>
//             <option value="Canada">Canada</option>
//           </select>
//         </span>
//         <div id="embeddingContainer"></div>
//       </body> */}

//       <div id="embeddingContainer"></div>
//     </div>
//   );
// };

export default QuickSightDashboard;
