import React from "react";

import Menubar from "../components/menubar";
import Footer from "../components/footer/footer";
import MainBanner from "../components/main-banner";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Carousel from "react-bootstrap/Carousel";

const fakeImageStyle = {
  height: "200px",
};

const fakeImageSmallStyle = {
  height: "60px",
};

class ParseJwtUrlHome extends React.Component {
  hasAccessTokenInUrl() {
    const queryParamString = window.location.hash.split("#")[1];

    const isAccessTokenInUrl = /^access_token=/.test(queryParamString);
    console.log("isAccessTokenInUrl", isAccessTokenInUrl);

    return isAccessTokenInUrl;
  }

  isValidJwt(jwt) {
    if (
      jwt &&
      Object.keys(jwt).length === 0 &&
      Object.getPrototypeOf(jwt) === Object.prototype
    ) {
      return false;
    } else if (jwt.exp * 1000 < Date.now()) {
      return false;
    } else {
      return true;
    }
  }

  parseJwt(token) {
    console.log("token", token);

    if (!token) {
      return {};
    }
    if (
      token &&
      Object.keys(token).length === 0 &&
      Object.getPrototypeOf(token) === Object.prototype
    ) {
      return {};
    }

    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  getQueryVariable(queryParamStirng, variable) {
    let vars;
    try {
      vars = queryParamStirng.split("&");
    } catch (e) {
      return {};
    }

    const queryParams = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");

      queryParams[pair[0]] = pair[1];

      // if (decodeURIComponent(pair[0]) == variable) {
      //   return decodeURIComponent(pair[1]);
      // }
    }

    return queryParams;

    console.log("Query variable %s not found", variable);
  }

  render() {
    let isAuthenticated = false;

    console.log("render");

    if (this.hasAccessTokenInUrl()) {
      const queryParamString = window.location.hash.split("#")[1];

      const queryParams = this.getQueryVariable(
        queryParamString,
        "access_token"
      );
      const jwt = this.parseJwt(queryParams.access_token);

      // TODO: Save jwt to state, set authenticated to true

      console.log("isValidJwt(jwt)", this.isValidJwt(jwt));

      if (this.isValidJwt(jwt)) {
        isAuthenticated = true;

        return <Navigate to="/" />;
      } else {
        isAuthenticated = false;
      }
    }

    return <div />;
  }
}

const plantWatcherImages = [
  {
    src: "/plant-watcher-v1-0-wide.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/plant-watcher-v1-0-close.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
];

const weatherStationZeroImages = [
  {
    src: "/ws-zero-v0-1-outdoors.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/ws-zero-v0-1-circuit-close.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/ws-zero-v0-1-inside-close.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
  {
    src: "/ws-zero-v0-1-underside.jpg?text=First slide&bg=373940",
    label: "",
    description: "",
  },
];

const weatherStationOneImages = [
  {
    src: "/ws-one-v0-1-circuit-close.jpg",
    label: "",
    description: "",
  },
  {
    src: "/ws-one-v0-1-exterior-close.jpg",
    label: "",
    description: "",
  },
  {
    src: "/ws-one-v0-1-outdoors-sunny.jpg",
    label: "",
    description: "",
  },
  {
    src: "/ws-one-v0-1-outdoors-cloudy.jpg",
    label: "",
    description: "",
  },
];

function buildCarouselItem(image) {
  return (
    <Carousel.Item>
      <img className="d-block w-100" src={image.src} alt="First slide" />
      <Carousel.Caption>
        <h3>{image.label}</h3>
        <p>{image.description}</p>
      </Carousel.Caption>
    </Carousel.Item>
  );
}

function UncontrolledExample(images) {
  return (
    // <Container>
    <Carousel>{images.map((image) => buildCarouselItem(image))}</Carousel>
    // </Container>
  );
}

const contentStyle = {
  backgroundColor: "#fafafa",
};

class Home extends React.Component {
  render() {
    return (
      <div>
        {/* <ParseJwtUrlHome /> */}
        <Menubar />
        <MainBanner />

        <div style={contentStyle}>
          <br />
          <br />
          <div className="container">
            <h2>GardenGo</h2>
            <p>
              GardenGo is a custom-built weather station and plant watcher. It
              primarily includes:
              <ol>
                <li>A Terraform, Go & Python backend deployed on AWS.</li>
                <li>
                  A Go & Python app deployed on a number of Raspberry Pis in
                  order to gather sensor data.
                </li>
                <li>
                  A React & Bootstrap frontend (note: I'm not a frontend
                  developer).
                </li>
              </ol>
            </p>
          </div>
          <br />
          <br />
          <div className="container">
            <h3>Weather Station - Passive (ws0)</h3>
            <p>
              GardenGo has a number of passive sensors deployed that monitor the
              current weather conditions. These sensors currently monitor the
              surrounding temperature and humidity.
            </p>
            <p>
              This station includes a 120mm fan that brings airflow over the
              sensors, ensuring that the sensor's reading are always
              representative of their environment.
            </p>
            {UncontrolledExample(weatherStationZeroImages)}
          </div>
          <br />
          <br />
          <div className="container">
            <h3>Weather Station - Active (ws1)</h3>
            <p>
              Active sensors are helpful when making certain measurements. These
              sensors provide insight regarding: wind speeds, wind gusts, wind
              direction and rainfall. Please note that this station is currently
              deployed on a appartment balcony, so the reading provided will not
              always be accurate, especially regarding wind direction.
            </p>
            {UncontrolledExample(weatherStationOneImages)}
          </div>
          <br />
          <br />
          <div className="container">
            <h3>Plant Watcher</h3>
            <p>
              GardenGo also supports plant watching and watering. Testing is
              currently being done in order to determine a reliable way to get
              soil moisture content.
            </p>
            {UncontrolledExample(plantWatcherImages)}
          </div>
          <br />
          <br />
        </div>

        {/* <div className="row">
          <div className="side">
            <h2>About Me</h2>
            <h5>Photo of me:</h5>
            <div className="fakeimg" style={fakeImageStyle}>
              Image
            </div>
            <p>
              Some text about me in culpa qui officia deserunt mollit anim..
            </p>
            <h3>More Text</h3>
            <p>Lorem ipsum dolor sit ame.</p>
            <div className="fakeimg" style={fakeImageSmallStyle}>
              Image
            </div>
            <br />
            <div className="fakeimg" style={fakeImageSmallStyle}>
              Image
            </div>
            <br />
            <div className="fakeimg" style={fakeImageSmallStyle}>
              Image
            </div>
          </div>
          <div className="main">
            <h2 fontSize="1000px" color="#f7d1a2">
              TITLE HEADING
            </h2>
            <h5>Title description, Dec 7, 2017</h5>
            <div className="fakeimg" style={fakeImageStyle}>
              Image
            </div>
            <p>Some text..</p>
            <p>
              Sunt in culpa qui officia deserunt mollit anim id est laborum
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco.
            </p>
            <br />
            <h2>TITLE HEADING</h2>
            <h5>Title description, Sep 2, 2017</h5>
            <div className="fakeimg" style={fakeImageStyle}>
              Image
            </div>
            <p>Some text..</p>
            <p>
              Sunt in culpa qui officia deserunt mollit anim id est laborum
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco.
            </p>
          </div>
        </div> */}

        <Footer />
      </div>
    );
  }
}

export default Home;
