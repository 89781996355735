import React from "react";

import Menubar from "../components/menubar";

class About extends React.Component {
  render() {
    return <Menubar />;
  }
}

export default About;
