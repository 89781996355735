import { ConsoleLogger } from "@aws-amplify/core";
import React from "react";

import Menubar from "../components/menubar";
import QuickSightDashboard from "../components/quicksight";
import Footer from "../components/footer/footer";

class Insights extends React.Component {
  render() {
    // const jwt = JSON.parse(localStorage.getItem("jwt"));
    // const username = jwt.username;

    // console.log("username", username);

    return (
      <div>
        <br />
        <br />
        {/* <br />
        <br />
        <br />
        <br />
        <br /> */}
        <Menubar />
        <QuickSightDashboard />
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Insights;
