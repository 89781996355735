import "bootstrap/dist/css/bootstrap.min.css";
import "./AppPostBootstrap.css";
import React, { useContext, createContext, useState } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Insights from "./pages/insights";
import Home from "./pages/home";
import ComingSoon from "./pages/coming-soon";
import NotFound from "./pages/not-found";
import About from "./pages/about";
import Dashboard from "./pages/dashboard";

class Login extends React.Component {
  static pageHasAccessTokenInUrl() {
    const queryParamString = window.location.hash.split("#")[1];

    const isAccessTokenInUrl = /^access_token=/.test(queryParamString);
    console.log("isAccessTokenInUrl", isAccessTokenInUrl);

    return isAccessTokenInUrl;
  }

  static parseJwt(token) {
    console.log("token", token);

    if (!token) {
      return {};
    }
    if (
      token &&
      Object.keys(token).length === 0 &&
      Object.getPrototypeOf(token) === Object.prototype
    ) {
      return {};
    }

    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  static isLoggedIn() {
    if (
      localStorage.getItem("jwtDecoded") &&
      Login.isValidJwt(JSON.parse(localStorage.getItem("jwtDecoded")))
    ) {
      console.log(JSON.parse(localStorage.getItem("jwtDecoded")));
      return true;
    } else {
      return false;
    }
  }

  static isValidJwt(jwt) {
    if (
      jwt &&
      Object.keys(jwt).length === 0 &&
      Object.getPrototypeOf(jwt) === Object.prototype
    ) {
      return false;
    } else if (jwt.exp * 1000 < Date.now()) {
      return false;
    } else {
      return true;
    }
  }

  static getQueryVariable(queryParamStirng) {
    let vars;
    try {
      vars = queryParamStirng.split("&");
    } catch (e) {
      return {};
    }

    const queryParams = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");

      queryParams[pair[0]] = pair[1];
    }

    return queryParams;
  }

  static fetchAccessTokenFromUrl() {
    if (Login.pageHasAccessTokenInUrl()) {
      const queryParamString = window.location.hash.split("#")[1];

      const queryParams = Login.getQueryVariable(queryParamString);

      const jwtEncoded = queryParams.access_token;
      const jwtDecoded = Login.parseJwt(jwtEncoded);

      if (Login.isValidJwt(jwtDecoded)) {
        localStorage.setItem("jwtEncoded", jwtEncoded);
        localStorage.setItem("jwtDecoded", JSON.stringify(jwtDecoded));
      } else {
        localStorage.setItem("jwtEncoded", jwtEncoded);
        localStorage.setItem("jwtDecoded", JSON.stringify(jwtDecoded));
      }

      window.location.replace(window.location.origin);
    }
  }

  componentDidMount() {
    const cognitoDomain = "gardengo";
    const clientId = "3pp53klei04rs697e9coq8brui";
    const responseType = "token";
    const scope = "garden-go-test%2Fviewer";

    const redirectUri = encodeURIComponent(window.location.origin);

    window.location.replace(
      `https://${cognitoDomain}.auth.us-east-1.amazoncognito.com/login?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`
    );
  }

  render() {
    return <></>;
  }
}

Login.fetchAccessTokenFromUrl();

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/"
          // element={<Home />}
          element={Login.isLoggedIn() ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          path="/status"
          // element={<ComingSoon />}
          element={
            Login.isLoggedIn() ? <ComingSoon /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/dashboard"
          // element={<Dashboard />}
          element={
            Login.isLoggedIn() ? <Dashboard /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/plants"
          // element={<ComingSoon />}
          element={Login.isLoggedIn() ? <About /> : <Navigate to="/login" />}
        />
        <Route
          path="/insights"
          // element={<Insights />}
          element={Login.isLoggedIn() ? <Insights /> : <Navigate to="/login" />}
        />
        <Route
          path="/subscribe"
          // element={<ComingSoon />}
          element={
            Login.isLoggedIn() ? <ComingSoon /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/about"
          // element={<ComingSoon />}
          element={Login.isLoggedIn() ? <About /> : <Navigate to="/login" />}
        />
        <Route
          path="*"
          // element={<NotFound />
          element={Login.isLoggedIn() ? <NotFound /> : <Navigate to="/login" />}
        />
      </Routes>
    </div>
  );
}

export default App;
